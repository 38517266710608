//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkEditUtil, S25BulkMap, MappingBeanI } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { jSith } from "../../../util/jquery-replacement";
import { PayloadI } from "../../../pojo/EventTypeBulkEditPayloadI";
import { Report } from "../../../pojo/Report";
import { EventService } from "../../../services/event.service";
import { ReportService } from "../../../services/report.service";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { S25ItemI } from "../../../pojo/S25ItemI";
import Use = Report.Use;
import ObjectType = Report.ObjectType;
import Reports = Report.Reports;

@TypeManagerDecorator("s25-ng-bulk-edit-event-type-reports")
@Component({
    selector: "s25-ng-bulk-edit-event-type-reports",
    template: `
        <s25-loading-inline model="{}"></s25-loading-inline>
        @if (this.init) {
            <div>
                <div class="c-margin-top--half c-margin-bottom--half">
                    <div class="ngBold c-margin-top--half">Select {{ this.confModelBean.title }}</div>
                    <s25-generic-dropdown
                        [items]="this.standardInvoiceReportsDropdownList"
                        [placeholder]="'Select from Report'"
                        [searchEnabled]="true"
                        [staticItems]="this.staticItems"
                        [(chosen)]="this.chosenConf"
                        (chosenChange)="onChosen('Confirmation')"
                    ></s25-generic-dropdown>
                    @if (this.chosenConf && this.chosenConf.itemId) {
                        <button
                            class="aw-button aw-button--danger--transparent elt--primaryOrg-margin-top"
                            (click)="removeChosenType('Confirmation')"
                        >
                            Remove
                        </button>
                    }
                </div>
                <div class="c-margin-top--half c-margin-bottom--half">
                    <div class="ngBold c-margin-top--half">Select {{ this.invModelBean.title }}</div>
                    <s25-generic-dropdown
                        [items]="this.standardInvoiceReportsDropdownList"
                        [placeholder]="'Select from Report'"
                        [searchEnabled]="true"
                        [staticItems]="this.staticItems"
                        [(chosen)]="this.chosenInv"
                        (chosenChange)="onChosen('StandardInvoice')"
                    ></s25-generic-dropdown>
                    @if (this.chosenInv && this.chosenInv.itemId) {
                        <button
                            class="aw-button aw-button--danger--transparent elt--primaryOrg-margin-top"
                            (click)="removeChosenType('StandardInvoice')"
                        >
                            Remove
                        </button>
                    }
                </div>

                <div class="c-margin-top--half c-margin-bottom--half">
                    <div class="ngBold c-margin-top--half">Select {{ this.paymentModelBean.title }}</div>
                    <s25-generic-dropdown
                        [items]="this.paymentReportsDropdownList"
                        [placeholder]="'Select from Report'"
                        [searchEnabled]="true"
                        [staticItems]="this.staticItems"
                        [(chosen)]="this.chosenPayment"
                        (chosenChange)="onChosen('PaymentInvoice')"
                    ></s25-generic-dropdown>
                    @if (this.chosenPayment && this.chosenPayment.itemId) {
                        <button
                            class="aw-button aw-button--danger--transparent elt--primaryOrg-margin-top"
                            (click)="removeChosenType('paymentInvoice')"
                        >
                            Remove
                        </button>
                    }
                </div>
                <span class="c-margin-bottom--single c-margin-right--half ngInlineBlock ngBold"
                    >Add/Remove {{ this.title }}</span
                >
                <s25-toggle-button
                    (modelValueChange)="this.onToggleChange($event)"
                    [modelValue]="this.remove"
                    [trueLabel]="trueLabel"
                    [falseLabel]="falseLabel"
                ></s25-toggle-button>
                @if (this.modelBeanInit) {
                    <div>
                        <s25-ng-multiselect-search-criteria
                            [selectedItems]="this.selectedItems"
                            [(modelBean)]="this.modelBean"
                            honorMatching="true"
                            [type]="this.type"
                        ></s25-ng-multiselect-search-criteria>
                    </div>
                }
                <s25-ng-bulk-edit-save
                    [submitFn]="updateFn"
                    [buttonText]="this.saveButtonText"
                    [buttonDisabled]="this.buttonDisabled"
                ></s25-ng-bulk-edit-save>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditEventTypeReportsComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemType?: any = undefined;
    @Input() itemIds: any = undefined;
    @Input() fields?: string[] = ["quantity", "comments"]; //Used to hide fields
    @Input() idsOnly: boolean = false; // Event cat pass ids instead of the object, so make this flexible either just ids or the object
    @Input() falseLabel: string = "Add";
    @Input() trueLabel: string = "Remove";
    modelBean: DropDownItem = { showResult: true, showMatching: false };
    init: boolean = false;
    type: any;
    remove: boolean = false;
    mappingModelBean: any;
    title: any;
    saveButtonText: string;
    customFilterValue: string = "";
    confModelBean: MappingBeanI;
    invModelBean: MappingBeanI;
    paymentModelBean: MappingBeanI;
    chosenConf: S25ItemI = [];
    chosenInv: S25ItemI = [];
    chosenPayment: S25ItemI = [];
    selectedItems: S25ItemI[] = []; //[{itemId: -2, itemName: 'Scheduler', isPermanent: true}, {itemId: -1, itemName: 'Requestor', isPermanent: true}];
    modelBeanInit: boolean = false;
    modelBeanInitAddItems: any;

    staticItems = [
        {
            dropDownOrigName: "No Default",
            isSelected: true,
            itemId: 0,
            itemName: "No Default",
            noAnchorClick: true,
            txt: "No Default",
            val: "",
        },
    ];
    reportsList: Report.Object[];
    paymentReportsDropdownList: Report.Object[];
    standardInvoiceReportsDropdownList: Report.Object[];

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        await this.getEventTypeReports();
        const objectTypes = [ObjectType.Event, ObjectType.EventDocument, ObjectType.OrganizationDocument];
        this.standardInvoiceReportsDropdownList = this.reportsList.filter(
            (item: any) =>
                objectTypes.indexOf(item.object_type) > -1 &&
                item.report_id !== Reports.OrganizationShell.id &&
                item.report_id !== Reports.ReservationShell.id &&
                item.report_id !== Reports.EventShell.id &&
                item.object_type !== ObjectType.PaymentDocument,
        );

        this.paymentReportsDropdownList = this.reportsList.filter((report) => {
            const isPaymentReport = Number(report.object_type) === ObjectType.PaymentDocument;
            const isShell = Number(report.report_id) === Reports.PaymentShell.id;
            return isPaymentReport && !isShell;
        });

        this.mappingModelBean = S25BulkMap[this.itemTypeId][this.itemType];
        this.confModelBean = S25BulkMap[this.itemTypeId]["reportConfirm"];
        this.invModelBean = S25BulkMap[this.itemTypeId]["reportInvoice"];
        this.paymentModelBean = S25BulkMap[this.itemTypeId]["reportPayment"];
        this.type = this.mappingModelBean.searchCriteriaType;
        this.modelBean.hasQuantity = this.mappingModelBean.hasQuantity;
        this.title = this.mappingModelBean.title;
        this.saveButtonText = "Add " + this.mappingModelBean.title;
        this.init = true;
        this.modelBeanInit = true;
        this.cd.detectChanges();

        // call search criteria data
        this.modelBean.dataPromise &&
            this.modelBean.dataPromise.then(() => {
                S25BulkEditUtil.done(this.elementRef.nativeElement);
                this.cd.detectChanges();
            });
    }

    onChosen(type: ChosenReportType) {
        switch (type) {
            case "Confirmation": {
                this.chosenConf.isPermanent = true;
                this.chosenConf.itemId !== 0 ? this.reSetSelectItems() : "";
                break;
            }
            case "StandardInvoice": {
                this.chosenInv.isPermanent = true;
                this.chosenInv.itemId !== 0 ? this.reSetSelectItems() : "";
                break;
            }
            default: {
                this.chosenPayment.isPermanent = true;
                this.chosenPayment.itemId !== 0 ? this.reSetSelectItems() : "";
                break;
            }
        }
    }

    reSetSelectItems() {
        if (!this.remove) {
            let modelBean = this.modelBean;
            this.selectedItems = [];
            this.modelBeanInit = false;
            this.cd.detectChanges();

            this.chosenConf.length !== 0 ? this.selectedItems.push(this.chosenConf) : "";
            this.chosenInv.length !== 0 ? this.selectedItems.push(this.chosenInv) : "";
            this.chosenPayment.length !== 0 ? this.selectedItems.push(this.chosenPayment) : "";

            jSith.forEach(modelBean.addedItems, (key, i) => {
                if (
                    i.itemId !== this.chosenInv.itemId &&
                    i.itemId !== this.chosenConf.itemId &&
                    i.itemId !== this.chosenPayment.itemId
                ) {
                    this.selectedItems.push({
                        itemName: i.itemName,
                        itemId: i.itemId,
                        checked: true,
                        isPermanent: false,
                    });
                }
            });

            this.selectedItems = S25Util.array.unique(this.selectedItems);
            this.modelBeanInit = true;
            this.cd.detectChanges();
        }
    }

    onToggleChange(event: any) {
        this.remove = event;
        let removeButtonText = "Remove " + this.mappingModelBean.title;
        let addButtonText = "Add " + this.mappingModelBean.title;
        this.saveButtonText = this.remove ? removeButtonText : addButtonText;
        this.cd.detectChanges();
    }

    removeChosenType(type: ChosenReportType) {
        switch (type) {
            case "Confirmation": {
                this.chosenConf = [];
                this.reSetSelectItems();
                break;
            }
            case "StandardInvoice": {
                this.chosenInv = [];
                this.reSetSelectItems();
                break;
            }
            default: {
                this.chosenPayment = [];
                this.reSetSelectItems();
                break;
            }
        }
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        let addItemIds: any = [];
        let removeItemIds: any = [];
        this.remove ? (removeItemIds = this.modelBean.addedItems) : (addItemIds = this.modelBean.selectedItems);
        if (this.idsOnly) {
            addItemIds = S25Util.toItemIds(addItemIds);
            removeItemIds = S25Util.toItemIds(removeItemIds);
        }
        let reportSelectedItems: any = [];
        let selectedItems: any = {};
        let payload: PayloadI = undefined;

        selectedItems.ancillary = {};

        if (this.remove) {
            let ancillary = {};
            jSith.forEach(removeItemIds, (key, i) => {
                if (i.itemId !== this.chosenInv.itemId && i.itemId !== this.chosenConf.itemId) {
                    reportSelectedItems.push({ reportId: i.itemId });
                }
            });
            this.chosenConf.length !== 0
                ? this.chosenConf.itemId === 0
                    ? (selectedItems.confirmation = "")
                    : (selectedItems.confirmation = { reportId: this.chosenConf.itemId })
                : ""; //add confirmation
            this.chosenInv.length !== 0
                ? this.chosenInv.itemId === 0
                    ? (selectedItems.invoice = "")
                    : (selectedItems.invoice = { reportId: this.chosenInv.itemId })
                : "";
            reportSelectedItems.length !== 0 ? (ancillary = reportSelectedItems) : ""; // add ancillary
            payload = { idList: this.itemIds, reports: selectedItems, remove: { reports: ancillary } };
        } else {
            jSith.forEach(addItemIds, (key, i) => {
                reportSelectedItems.push({ reportId: i.itemId });
            });
            this.chosenConf.length !== 0
                ? this.chosenConf.itemId === 0
                    ? (selectedItems.confirmation = "")
                    : (selectedItems.confirmation = { reportId: this.chosenConf.itemId })
                : ""; //add confirmation
            this.chosenInv.length !== 0
                ? this.chosenInv.itemId === 0
                    ? (selectedItems.invoice = "")
                    : (selectedItems.invoice = { reportId: this.chosenInv.itemId })
                : ""; //add invoice

            this.chosenPayment.length !== 0
                ? this.chosenPayment.itemId === 0
                    ? (selectedItems.payment = "")
                    : (selectedItems.payment = { reportId: this.chosenPayment.itemId })
                : ""; //add invoice

            reportSelectedItems.length !== 0 ? (selectedItems.ancillary = reportSelectedItems) : ""; // add ancillary
            payload = { idList: this.itemIds, reports: selectedItems };
        }
        return this.mappingModelBean.service(payload);
    } //END update

    async getEventTypeReports() {
        return EventService.getEventTypeReports().then((data: any) => {
            this.reportsList = ReportService.normalizeReport(data, true);
        });
    }
}

export type ChosenReportType = "Confirmation" | "StandardInvoice" | "PaymentInvoice";
