import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { VariableI } from "../../../pojo/VariableI";
import { S25BulkEditSaveApi } from "../save/s25.bulk.edit.save.api";
import { S25CustomAttrDropdownApi } from "../../s25-dropdown/single-select/s25.custom.attr.dropdown.api";
import { CustomAttributeService } from "../../../services/custom.attribute.service";
import {
    CustomAttribute,
    S25CustomAttributeItemFormatter,
} from "../../s25-custom-attribute/s25.custom.attribute.item.formatter";
import { S25EditableCustomAttributeComponent } from "../../s25-editable/s25.editable-custom-attribute/s25.editable-custom-attribute.component";
import { DropdownApi } from "../../s25-dropdown/dropdown.api";
import { DropDownItem } from "../../../pojo/DropDownItem";

@TypeManagerDecorator("s25-ng-bulk-edit-cust-atrb")
@Component({
    selector: "s25-ng-bulk-edit-cust-atrb",
    template: `
        <s25-loading-inline model="{}"></s25-loading-inline>
        @if (this.init) {
            <div class="attributeTopSpacer">
                <s25-custom-attr-dropdown
                    [itemTypeId]="itemTypeId"
                    [(chosen)]="chosen"
                    (chosenChange)="onChosen($event)"
                    [whitelistedTypes]="[]"
                    [filter]="hideReadOnly"
                ></s25-custom-attr-dropdown>
                @if (chosen?.custAtrbType && !remove) {
                    <div>
                        <s25-ng-editable-custom-attribute
                            (valChange)="onValueChange($event)"
                            [readOnly]="false"
                            [attributeName]="chosen?.txt"
                            [attributeId]="chosen?.itemId"
                            [attributeType]="chosen?.custAtrbType"
                            [multiVal]="chosen?.multi_val"
                        ></s25-ng-editable-custom-attribute>
                    </div>
                }
                @if (chosen?.custAtrbType) {
                    <s25-ng-bulk-edit-save
                        [submitFn]="updateFn"
                        [buttonText]="saveButtonText"
                        [buttonDisabled]="buttonDisabled"
                    ></s25-ng-bulk-edit-save>
                }
            </div>
        }
    `,
    styles: `
        .attributeTopSpacer {
            margin-top: 20px;
        }
        .attributeRow {
            display: flex;
            gap: 1em;
            align-items: center;
            margin: 0.5em 0;
        }

        .attributeRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .attributeRow > input {
            min-width: 15em;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditCustAtrbComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemType?: any = undefined;
    @Input() itemIds: any = undefined;
    @Input() remove: boolean = false;
    @ViewChild(S25EditableCustomAttributeComponent) editableCustAttrComponent: S25EditableCustomAttributeComponent;

    init: boolean = false;
    mappingModelBean: any;
    title: string;
    saveButtonText: string = "Remove Custom Attributes";
    chosen: VariableI;
    buttonDisabled: boolean = true;
    value: any;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        this.remove
            ? (this.saveButtonText = "Remove Custom Attributes")
            : (this.saveButtonText = "Update Custom Attributes");
        this.mappingModelBean = S25BulkMap[this.itemTypeId][this.itemType];
        this.init = true;
        this.cd.detectChanges();
    }

    async onChosen(e: VariableI) {
        this.value = undefined;
        if (typeof this.chosen.custAtrbType === "string") {
            this.chosen.custAtrbType = this.chosen.custAtrbType.toUpperCase();
            if (this.chosen?.multi_val && this.chosen?.multi_val === 1) {
                let el: any = "s25-ng-editable-custom-attribute";
                DropdownApi.refresh(el, this.editableCustAttrComponent?.discreteOptions?.[this.chosen.itemId]);
            }
        }
        await S25Util.delay(720);
        this.buttonDisabled = false;
        this.cd.detectChanges();
        S25BulkEditSaveApi.reset(this.elementRef.nativeElement);
    }

    onValueChange(e: any) {
        this.chosen.custAtrbType === "I" ? (this.value = { imageId: e.imageId }) : (this.value = e);
    }

    get updateFn() {
        return this.update.bind(this);
    }

    //Some attributes are read only, so we need to hide them from the dropdown
    //Will be run in the context of the child
    hideReadOnly(item: DropDownItem) {
        return !CustomAttributeService.isReadOnly(+item.val);
    }

    update() {
        if (this.remove) {
            return CustomAttributeService.delCustomAttribute(this.itemIds, this.chosen.itemId, this.itemTypeId);
        } else {
            if (this.value === undefined) {
                alert("Please enter a value for the custom attribute.");
                return false;
            }

            if (this.chosen.custAtrbType === "F") {
                if (!S25Util.isFloat(this.value) || !("" + this.value).match(/^-?\d+\.?\d*$|^-?\d*\.?\d+$/)) {
                    alert("Please enter a valid float");
                    return false;
                }
            }

            const putFormatCustAttrb: CustomAttribute.Attribute = {
                itemTypeId: this.chosen.custAtrbType as CustomAttribute.Type,
            };

            if (this.value.itemId) putFormatCustAttrb.itemId = this.value.itemId; // select dropdown  location, contact, organiztion and resource

            let newValue: any = this.value;

            if (this.chosen.custAtrbType !== "I")
                newValue = S25CustomAttributeItemFormatter.putFormat(putFormatCustAttrb, this.value, false);

            if (this.chosen.custAtrbType === "S" && this.chosen?.multi_val === 1) {
                if (!Array.isArray(this.value)) {
                    alert("Please enter a value for the custom attribute.");
                    return false;
                }
                newValue = JSON.stringify(
                    this.value.sort(S25Util.shallowSort("itemId")).map((chosen: any) => {
                        return { item: chosen.itemId };
                    }),
                );
            }
            return CustomAttributeService.editCustomAttribute(
                this.itemIds,
                this.chosen.itemId,
                this.chosen.custAtrbType,
                newValue,
                this.itemTypeId,
            );
        }
    }
}
