//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { taskMicroI, TaskUpdateI } from "../../../services/task/task.edit.service";
import { Task } from "../../../pojo/Task";
import { TaskUtil } from "../../s25-task/task.util";
import { NotificationService } from "../../../services/notification.service";

@TypeManagerDecorator("s25-ng-bulk-edit-assignee")
@Component({
    selector: "s25-ng-bulk-edit-assignee",
    template: `
        <s25-loading-inline model="{}"></s25-loading-inline>
        @if (init) {
            <div class="c-margin-top--half">
                <s25-ng-task-contacts-picker
                    [isOnBody]="true"
                    [noCommit]="true"
                    (changes)="onChange($event)"
                ></s25-ng-task-contacts-picker>
                <div class="changeList">
                    @for (change of changeList; track change.label) {
                        <div class="c-margin-top--half">
                            <div class="ngBold">{{ change.label }}:</div>
                            @for (contact of changes[change.prop]; track contact.itemId) {
                                <div class="c-margin-left--half">
                                    {{ contact.itemName }}
                                </div>
                            }
                        </div>
                    }
                </div>

                <s25-ng-bulk-edit-save
                    [submitFn]="updateFn"
                    [buttonText]="saveButtonText"
                    [buttonDisabled]="buttonDisabled"
                ></s25-ng-bulk-edit-save>
                @if (isDone && errorCount === 0) {
                    <div class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single" role="alert">
                        <div class="cn-alert__icon cn-icon" name="alert--info">
                            <svg class="cn-svg-icon" role="img">
                                <title>Success Alert</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                ></use>
                            </svg>
                        </div>
                        <div class="cn-alert__label">
                            <div>
                                Success!
                                <div></div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    `,
    styles: `
        .changeList {
            display: flex;
            flex-flow: row wrap;
            gap: 1em;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditAssigneeComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemType?: any = undefined;
    @Input() itemIds: any = undefined;
    init: boolean = false;
    type: any;
    mappingModelBean: any;
    changes: taskMicroI = {};
    changeList: { label: string; prop: keyof taskMicroI }[] = [];
    todoItemIds: any = [];
    isDone: boolean = false;
    errorCount = 0;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        this.mappingModelBean = S25BulkMap[this.itemTypeId][this.itemType];
        //todo
        this.todoItemIds = S25Util.deepCopy(this.itemIds);
        this.todoItemIds = this.todoItemIds.filter(function (x: any) {
            return x.indexOf("todo") !== -1;
        });
        this.todoItemIds = this.todoItemIds.map((x: any) => x.replace("todo", ""));
        //others
        //this.itemIds = this.itemIds.map((x : any)  => x.replace('task_multi_',""));
        // this.itemIds = this.itemIds.map((x : any)  => x.replace('task',""));
        this.itemIds = this.itemIds.filter(function (x: any) {
            return x.indexOf("todo") === -1;
        });
        this.itemIds = await S25BulkEditUtil.getMulitTaskIds(this.itemIds);
        parseInt(this.itemTypeId) === 10 ? (this.itemIds = this.itemIds.map((x: any) => x.replace("todo", ""))) : "";

        this.init = true;
        this.cd.detectChanges();
    }

    onChange(changes: taskMicroI) {
        Object.assign(this.changes, changes);
        this.updateChangeList();
        this.cd.detectChanges();
    }

    updateChangeList() {
        const changes = this.changes;
        this.changeList = [];
        if (!!changes.assignContact?.length) this.changeList.push({ label: "Assign Contacts", prop: "assignContact" });
        if (!!changes.unassignContact?.length)
            this.changeList.push({ label: "Unassign Contacts", prop: "unassignContact" });
        if (!!changes.assignGroup?.length) this.changeList.push({ label: "Assign Groups", prop: "assignGroup" });
        if (!!changes.unassignGroup?.length) this.changeList.push({ label: "Unassign Groups", prop: "unassignGroup" });
    }

    get updateFn() {
        return this.update.bind(this);
    }

    async update() {
        const payload: Task.WsDataI[] = [];

        const hasTasks = !!this.itemIds.length;
        const hasTodos = !!this.todoItemIds?.length;
        const hasContacts = !!this.changes.assignContact?.length || !!this.changes.unassignContact?.length;
        const hasGroups = !!this.changes.assignGroup?.length || !!this.changes.unassignGroup?.length;
        if (!hasContacts && !hasGroups) return; // No changes

        if (hasTasks && (hasContacts || hasGroups)) {
            payload.push({
                taskIdList: this.itemIds,
                assignContact: this.changes.assignContact?.map((c) => ({ contactId: Number(c.itemId) })),
                unassignContact: this.changes.unassignContact?.map((c) => ({ contactId: Number(c.itemId) })),
                assignGroup: this.changes.assignGroup?.map((c) => ({ groupId: Number(c.itemId) })),
                unassignGroup: this.changes.unassignGroup?.map((c) => ({ groupId: Number(c.itemId) })),
            });
        }

        if (hasTodos && hasContacts && !hasGroups) {
            payload.push({
                todoIdList: this.todoItemIds,
                assignContact: this.changes.assignContact?.map((c) => ({ contactId: Number(c.itemId) })),
            });
        }

        if (hasTodos && hasGroups) {
            alert("To Do task can't edit assignees by security group. To Dos will not be changed.");
        } else if (hasTodos && this.changes.assignContact?.length > 1) {
            alert("To Do task can only add one assignee. To Dos will not be changed.");
        }

        const resp: TaskUpdateI = await this.mappingModelBean.service(payload);

        const changes = TaskUtil.findChangedContacts(resp, {
            added: this.changes.assignContact,
            removed: this.changes.unassignContact,
        });

        const failedAdds = changes.failed.added.length;
        if (!!failedAdds) {
            NotificationService.post(
                `Unable to add ${failedAdds} contact${failedAdds > 1 ? "s" : ""} to the task. Make sure they have sufficient rights to approve it`,
            );
        }
        const failedRemoves = changes.failed.removed.length;
        if (!!failedRemoves) {
            NotificationService.post(
                `Unable to remove ${failedRemoves} contact${failedRemoves > 1 ? "s" : ""} from the task`,
            );
        }

        this.resetChanges();
        this.isDone = (resp.info as any) !== "No data to save";
        this.cd.detectChanges();
    }

    resetChanges() {
        this.changes = {};
        this.changeList = [];
    }
}
